<template>
  <div class='px-2 lg:pl-8'>
    <portal to='headerSpace'>
      <div class='flex flex-row'>
        <h1 class='text-lg lg:text-3xl font-semibold tracking-wide'>Updates</h1>
      </div>
    </portal>
    <history-filters
      @change-options='changeOptions' 
      @change-filter-params='changeFilterParams' 
      class='mb-8' />
    <div v-if='meetingNoteChecked'
      class='mb-20'>
      <meeting-note-card v-for='meetingNote in meetingNotes'
        :meetingNote='meetingNote'
        :key='`updates-meeting-mote-${meetingNote.id}`'
        @click.native='openMeetingNoteEdit(meetingNote.id)'>
      </meeting-note-card>
    </div>
    <el-timeline v-if='hasLogs'>
      <timeline-item v-for='log in changeLogs'
        :key='`changeLogs-${log.id}`'
        :log='log'
        placement='top'>
        <change-log
        :log='log'
        @toggle-sharing='toggleSharing'/>
      </timeline-item>
      <portal to='modals'>
        <comment-share-modal
          :shareable-link='shareableLink'
          @modal-close='modalClose' />
      </portal> 
    </el-timeline>
    <div v-else class='mx-auto w-64 text-center'>
      Please update your filters
    </div>
  </div>
</template>

<script>
import ChangeLog       from '@/components/investment/ChangeLog.vue'
import TimelineItem    from '@/components/investment/TimelineItem.vue'
import MeetingNoteCard from '@/components/meeting_notes/MeetingNoteCard.vue'
import HistoryFilters  from '@/views/dashboard/HistoryFilters.vue'
import CommentShareModal  from '@/components/investment/CommentShareModal.vue'

import { mapActions, mapState } from 'vuex'

export default {
  name: 'History',
  components: {
    ChangeLog,
    HistoryFilters,
    TimelineItem,
    MeetingNoteCard,
    CommentShareModal,
  },
  data () {
    return {
      filterParams: {
        filterText: ['comment', 'meeting note'],
        customFieldTemplateId: 0,
        drilldownInvestmentId: 0
      },
      shareableLink: '',
      checkedOptions: ['comment', 'meeting note'],
    }
  },
  computed: {
    ...mapState('changeLogs', [
      'changeLogs',
    ]),
    ...mapState('meetingNotes', [
      'meetingNotes',
    ]),
    hasLogs () {
      return this.changeLogs.length > 0
    },
    meetingNoteChecked () {
      return this.checkedOptions.includes('meeting note')
    },
  },
  methods: {
    ...mapActions('changeLogs', [
      'filterChangeLogs',
    ]),
    ...mapActions('customFields', [
      'getCustomFieldTemplates',
    ]),
    ...mapActions('meetingNotes', [
      'getMeetingNotes',
      'getMeetingNoteDetails',
    ]),
    changeOptions (checkedOptions) {
      this.checkedOptions = checkedOptions
    },
    changeFilterParams (filterParams) {
      this.filterChangeLogs(filterParams)
    },
    openMeetingNoteEdit (meetingNoteId) {
      this.getMeetingNoteDetails(meetingNoteId)
    },
    toggleSharing(link) {
      this.shareableLink = link
      this.$store.dispatch('modalOpen', {height: 16, width: 24})
    },
    modalClose () {
      this.$store.dispatch('modalClose')
    },
  },
  mounted () {
    this.filterChangeLogs(this.filterParams)
    this.getCustomFieldTemplates()
    this.getMeetingNotes()
  },
}
</script>
